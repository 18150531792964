import React from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Button } from "react-bootstrap";
import Helpers from "../../Libs/Helpers";

function Activate(props) {
  const onLength = (event) => {
    event.preventDefault();
    if (event.target.value.length > 1) {
        document.getElementById(
            "n" + parseInt(event.target.id.split("n")[1])
          ).value = "";
    } else {
      if (event.keyCode === 8) {
        try {
          const nextInput = parseInt(event.target.id.split("n")[1]) - 1;
          document.getElementById(
            "n" + parseInt(event.target.id.split("n")[1])
          ).value = "";
          document.getElementById("n" + nextInput).focus();
        } catch {
          console.log();
        }
      } else {
        try {
          const nextInput = parseInt(event.target.id.split("n")[1]) + 1;
          document.getElementById("n" + nextInput).focus();
        } catch {
          console.log();
        }
      }
    }
  };

  const activeDevice = (e) => {
    e.preventDefault();
    const inputs = document.getElementsByTagName('input')
    let inputValue = ""
    for (var index = 0; index < inputs.length; index++) {
      inputValue += inputs[index].value;
    }
    Helpers.queryAuth('/api/devices/activar', 'POST', localStorage.getItem('token'), { code: parseInt(inputValue), auth: localStorage.getItem('token'), id_user: localStorage.getItem('id') }).then((data) => {
      console.log(data)
      if (data.acknowledged === false){
        alert("Codigo Incorrecto", data)
      }
    })
  }

  return (
    <MainLayout>
      <div className="text-light">
        <h3>Asistente para la Activación de Dispositivo</h3>
        <br />
        Por Favor Ingrese el Código que aparece en la pantalla del dispositivo
        que desea vincular a su cuenta.
      </div>
      <div className="displayDevice p-5">
        <input
          id="n1"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
          }}
          autoFocus={true}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <input
          id="n2"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <input
          id="n3"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <input
          id="n4"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <div
          style={{
            display: "inline-flex",
            fontSize: 32,
            color: "#fff",
            marginLeft: 5,
          }}
        >
          -
        </div>
        <input
          id="n5"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <input
          id="n6"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <input
          id="n7"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
        <input
          id="n8"
          type="number"
          max="0"
          min="1"
          style={{
            width: 40,
            height: 60,
            borderRadius: 10,
            textAlign: "center",
            fontSize: 32,
            border: 0,
            marginLeft: 5,
          }}
          onKeyUp={(e) => {
            onLength(e);
          }}
        />
      </div>
      <Button id="n9" variant="danger" style={{ width: 460 }} onClick={(e) =>{
        activeDevice(e)
      }}>
        Activar Dispositivo
      </Button>
    </MainLayout>
  );
}

export default Activate;
