import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faVideo,
  faServer,
  faFilm,
  faRecordVinyl,
} from "@fortawesome/free-solid-svg-icons";

function AdminSide() {

  const navigate = useNavigate();
  const [selection, setSelection] = useState("");
  const [sideNavExpanded, setSideNavExpanded] = React.useState(false);


  return (
    <SideNav
      onSelect={(selected) => {
        const to = "/admin/" + selected;
        setSelection({ selected });
        navigate(to);
      }}
      onToggle={()=>{
        setSideNavExpanded(!sideNavExpanded)
      }}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected={selection}>
        <NavItem eventKey="home">
          <NavIcon>
            <FontAwesomeIcon icon={faHome} />
          </NavIcon>
          <NavText>Inicio</NavText>
        </NavItem>
        <NavItem eventKey="movies">
          <NavIcon>
            <FontAwesomeIcon icon={faFilm} />
          </NavIcon>
          <NavText>Peliculas</NavText>
        </NavItem>
        <NavItem eventKey="series">
          <NavIcon>
            <FontAwesomeIcon icon={faVideo} />
          </NavIcon>
          <NavText>Series</NavText>
        </NavItem>
        <NavItem eventKey="brokers">
          <NavIcon>
            <FontAwesomeIcon icon={faServer} />
          </NavIcon>
          <NavText>Brokers</NavText>
        </NavItem>
        <NavItem eventKey="producers">
          <NavIcon>
            <FontAwesomeIcon icon={faRecordVinyl} />
          </NavIcon>
          <NavText>Productoras</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default AdminSide;
