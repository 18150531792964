const API = 'https://clients.imesh.app'

const queryNoAuth = async (endpoint, method, body) => {
    try {
        return await fetch(API + endpoint, {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => response.json())
    } catch {
        console.info('Error')
    }
}

const queryAuth = async (endpoint, method, token, body) => {
    try {
        return await fetch(API + endpoint, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authentication': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }).then(response => response.json())
    } catch {
        console.info('Error')
    }
}

const validate = (navigate) => {
    if (!localStorage.getItem('token')) {
        navigate('/')
    }
}

const toSeconds = (timer) => {
    const a = timer.split(':');
    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return seconds;
}

export default { API, queryNoAuth, queryAuth, validate, toSeconds }