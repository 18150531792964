import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faVideo,
  faFilm,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

function TVSide() {

  const navigate = useNavigate();
  const [selection, setSelection] = useState("");


  return (
    <SideNav
      onSelect={(selected) => {
        const to = "/" + selected;
        setSelection({ selected });
        navigate(to);
      }}
      /*onToggle={()=>{
        setSideNavExpanded(!sideNavExpanded)
      }}*/
    >
      <SideNav.Nav defaultSelected={selection} className="p-4">
        <NavItem eventKey="profiletv" className="mb-4">
          <NavIcon>
            <div className="rightUser" style={{ width: 45, height: 45 }}></div>
          </NavIcon>
          <NavText>Inicio</NavText>
        </NavItem>
        <NavItem eventKey="hometv" className="mb-4">
          <NavIcon>
            <FontAwesomeIcon icon={faHome} className="fa-3x" />
          </NavIcon>
          <NavText>Inicio</NavText>
        </NavItem>
        <NavItem eventKey="moviestv" className="mb-4">
          <NavIcon>
            <FontAwesomeIcon icon={faFilm} className="fa-3x" />
          </NavIcon>
          <NavText>Peliculas</NavText>
        </NavItem>
        <NavItem eventKey="seriestv" className="mb-4">
          <NavIcon>
            <FontAwesomeIcon icon={faVideo} className="fa-3x" />
          </NavIcon>
          <NavText>Series</NavText>
        </NavItem>
        <NavItem eventKey="searchtv" className="mb-4">
          <NavIcon>
            <FontAwesomeIcon icon={faSearch} className="fa-3x" />
          </NavIcon>
          <NavText>Series</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default TVSide;
