import React from 'react';
import MainLayout from '../../Layouts/MainLayout';

function Brokers(props) {
    return (
        <MainLayout>
            <div className='text-light fw-bold h3'>
                Lista de Brokers
            </div>
            
        </MainLayout>
    );
}

export default Brokers;