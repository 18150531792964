import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Layouts/AdminLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faChevronLeft, faChildReaching, faClapperboard, faClock, faSave, faTimeline, faVideo } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { InputGroup, Form, Row, Col, Card, Image, Button } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';
import { faSquareLetterboxd } from '@fortawesome/free-brands-svg-icons';
import Helpers from '../../Libs/Helpers';
import { IMaskInput } from 'react-imask'
const DurationMask = '00:00:00'

function NewMovie(props) {

    const history = useNavigate();

    function handleClick(path) {
        history('/admin/' + path);
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const rangeYear = () => {
        const max = new Date().getFullYear()
        const min = max - 50
        const years = []

        for (let i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    }

    const saveMovie = () => {
        const body = {
            name,
            description,
            keys,
            classification,
            genders,
            duration,
            producer,
            year,
            poster,
            background,
            thumbnail,
            code,
            pre_end
        }
        Helpers.queryAuth('/api/movies/add', 'POST', localStorage.getItem('token'), body).then((data) => {
            if(data){
                history('/admin/movies')
            }
        })
    }

    const handleFileUpload = async (e, seteo) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        seteo(base64);
    };

    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [keys, setKeys] = useState()
    const [classification, setClassification] = useState()
    const [genders, setGenders] = useState()
    const [duration, setDuration] = useState()
    const [year, setYear] = useState()
    const [code, setCode] = useState()
    const [pre_end, setPre_end] = useState()
    const [producer, setProducer] = useState()
    const [poster, setPoster] = useState()
    const [background, setBackground] = useState()
    const [thumbnail, setThumbnail] = useState()
    const [prod, setProd] = useState([])

    useEffect(() => {
        Helpers.queryAuth('/api/producers/', 'POST', localStorage.getItem('token'), {}).then((data) => {
            setProd(data)
        })
    }, [])

    return (
        <AdminLayout>
            <div className='d-flex justify-content-between align-items-center mb-5'>
            <div className='d-flex text-light fw-bold h3'>
                <FontAwesomeIcon icon={faChevronLeft} className='me-3 text-light' role='button' onClick={() => handleClick("movies")} />
                Registro de Película
            </div>
            <div className='d-flex'>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        saveMovie()
                    }} variant='danger'>
                        <FontAwesomeIcon icon={faSave} className='me-3' />
                        Guardar Película
                    </Button>
            </div>
            </div>
            <div>
                <Row>
                    <Col sm={6} className='mb-3'>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faSquareLetterboxd} />
                            </InputGroupText>
                            <Form.Control
                                placeholder="Código de la Película"
                                aria-label="Código de la Película"
                                aria-describedby="codemovie"
                                type="text"
                                autoFocus={true}
                                defaultValue={code}
                                onChange={(e) => { setCode(e.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={6} className='mb-3'>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faSquareLetterboxd} />
                            </InputGroupText>
                            <Form.Control
                                placeholder="Titulo de la Película"
                                aria-label="Titulo de la Película"
                                aria-describedby="titmovie"
                                type="text"
                                autoFocus={true}
                                defaultValue={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={6} className='mb-3'>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroupText>
                            <Form.Select aria-label="Default select example" onChange={(e) => { setYear(e.target.value) }}>
                                <option>Seleccione Año Publicación</option>
                                {rangeYear().map((value, index) => {
                                    return (
                                        <option key={index} value={value}>{value}</option>
                                    )
                                })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col sm={6} className='mb-3'>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faClock} />
                            </InputGroupText>
                            <IMaskInput
                                className='form-control'
                                placeholder='Duración'
                                aria-label='Duración'
                                mask={DurationMask}
                                defaultValue={duration}
                                onChange={(e) => { setDuration(e.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={6} className='mb-3'>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            defaultValue={description}
                            onChange={(e) => { setDescription(e.target.value) }}
                            placeholder='Sinopsis de la Película'
                        />
                    </Col>
                    <Col sm={6} className='mb-3'>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            defaultValue={keys}
                            onChange={(e) => { setKeys(e.target.value) }}
                            placeholder='Palabras Clave para Identificar este Contenido, Separadas por Coma'
                        />
                    </Col>
                    <Col sm={3}>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faChildReaching} />
                            </InputGroupText>
                            <Form.Select aria-label="Clasificacion" onChange={(e) => { setClassification(e.target.value) }}>
                                <option>Clasificación</option>
                                <option value="7">7+</option>
                                <option value="13">13+</option>
                                <option value="17">17+</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col sm={3}>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faVideo} />
                            </InputGroupText>
                            <Form.Select aria-label="Producer" onChange={(e) => { setProducer(e.target.value) }}>
                                <option>Productora</option>
                                { prod.map((value, index) => {
                                    return (
                                        <option key={index} value={value._id}>{value.name}</option>        
                                    )
                                })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col sm={3}>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faTimeline} />
                            </InputGroupText>
                            <IMaskInput
                                className='form-control'
                                placeholder='Pre Final'
                                aria-label='Pre Final'
                                mask={DurationMask}
                                defaultValue={pre_end}
                                onChange={(e) => { setPre_end(e.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={3}>
                        <InputGroup>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faClapperboard} />
                            </InputGroupText>
                            <Form.Control 
                                type='text'
                                placeholder='Géneros'
                                onChange={(e) => { setGenders(e.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <div className='h3 fw-bold text-light mt-4 mb-3'>Archivos CDN</div>
                        <Row>
                            <Col sm={4}>
                                <Card>
                                    <Card.Header>Poster</Card.Header>
                                    <Card.Body>
                                        <Image src={poster} style={{ width: '100%', height: 'auto' }} />
                                    </Card.Body>
                                    <Card.Footer>
                                        <Form.Control
                                            type='file'
                                            onChange={(e) => handleFileUpload(e, setPoster)}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <Card.Header>Background</Card.Header>
                                    <Card.Body>
                                        <Image src={background} style={{ width: '100%', height: 'auto' }} />
                                    </Card.Body>
                                    <Card.Footer>
                                        <Form.Control
                                            type='file'
                                            onChange={(e) => handleFileUpload(e, setBackground)}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <Card.Header>Thumbnail</Card.Header>
                                    <Card.Body>
                                        <Image src={thumbnail} style={{ width: '100%', height: 'auto'}} />
                                    </Card.Body>
                                    <Card.Footer>
                                        <Form.Control
                                            type='file'
                                            onChange={(e) => handleFileUpload(e, setThumbnail)}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
            </div>
        </AdminLayout>
    );
}

export default NewMovie;