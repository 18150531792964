import React from 'react';
import MainLayout from '../../Layouts/MainLayout';

function Invoices(props) {
    return (
        <MainLayout>
            <div className='d-flex justify-content-between align-items-center' style={{ height: 50 }}>
                <div className='d-flex text-light h3 fw-bold'>Facturación</div>
            </div>
        </MainLayout>
    );
}

export default Invoices;