import React, { useEffect, useState } from 'react';
import AdminLayout from '../../Layouts/AdminLayout'
import { Button, Row, Col, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CardProducer from '../Producers/CardProducer';
import { useNavigate } from 'react-router-dom'
import Helpers from '../../Libs/Helpers';

function Producers(props) {

    const history = useNavigate()
    const [prod, setProd] = useState([])
    const [loading, setLoading] = React.useState(false)

    useEffect(()=>{
        Helpers.queryAuth('/api/producers/resumen', 'POST', localStorage.getItem('token'), {}).then((data)=>{
            setProd(data)
            setLoading(true)
        })
    },[])

    return (
        <AdminLayout>
            <div className='d-flex justify-content-between align-items-center mb-4'>
                <div className='d-flex'>
                    <div className='text-light fw-bold h3'>Productoras de Contenido</div>
                </div>
                <div className='d-flex'>
                    <Button variant='danger' onClick={(e)=>{ e.preventDefault(); history('/admin/newproducer') }}>
                        <FontAwesomeIcon icon={faPlus} className='me-3' />
                        Nueva Productora
                    </Button>
                </div>
            </div>
            {!loading ? <div className='d-flex justify-content-center align-items-center w-100 vh-75 mt-5'><Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} /></div> : 
            <Row>
                { prod.map((value, index) => {
                    return (
                    <Col key={index} sm={2}>
                        <CardProducer name={value.name} icon={ Helpers.API + '/cdn/' + value._id + '.png'} peliculas={value.movies} series={value.series} />
                    </Col>
                    )
                }) }
                
            </Row>
            }
        </AdminLayout>
    );
}

export default Producers;