import React, { useEffect, useState } from 'react';
import MobileLayout from '../../Layouts/MobileLayout';
import { Button, Form, InputGroup, Image, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChildren } from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../Libs/Helpers';
import { useNavigate } from 'react-router-dom';

function NewProfile(props) {

    const navigate = useNavigate()
    const [avatars, setAvatars] = useState([])
    const [avatar, setAvatar] = useState()
    const [nameProfile, setNameProfile] = useState()
    const [isChild, setIsChild] = useState(false)
    const [loader, setLoader] = useState(true)

    const [variante, setVariante] = useState('primary')
    const [alertMsg, setAlertMsg] = useState("")
    const [alertView, setAlertView] = useState(false)

    const showAlert = (variante, message) => {
        setVariante(variante)
        setAlertMsg(message)
        setAlertView(true)
        setTimeout(() => {
            setAlertView(false)
        }, 3000)
    }

    useEffect(() => {
        Helpers.queryAuth('/api/avatars', 'POST', localStorage.getItem('token'), {})
            .then((data) => {
                setAvatars(data)
                setLoader(false)
            })
    }, [])

    const registerProfile = () => {
        if (nameProfile === undefined) {
            showAlert('danger', 'Debes Ingresar un Nombre para el Perfil')
        } else if( avatar === undefined ){
            showAlert('danger', 'Selecciona un Avatar')
        } else {
            Helpers.queryAuth('/api/profiles/add', 'POST', localStorage.getItem('token'), {
                id_user: localStorage.getItem('id'),
                name: nameProfile,
                isChild,
                avatar,
                status: 1
            }).then((data) => {
                if(data){
                    navigate('/')
                }
            })
        }
    }

    const changeAvatar = (e) => {
        setAvatar(e.target.src.split('/')[4]);
        Array.prototype.map.call(document.images, (img) => {
            if (img.id === e.target.id) {
                document.getElementById(e.target.id).style.border = '4px solid #fff';
            } else {
                document.getElementById(img.id).style.border = '0px solid #fff';
            }

        });
    }

    return (
        <MobileLayout>
            <Alert className="fixed-top" style={{ display: alertView ? '' : 'none', textAlign: 'center', marginTop: 16, marginLeft: 16, marginRight: 16, width: 'auto' }} key={variante} variant={variante}>{alertMsg}</Alert>
            <div style={{
                width: '90vw',
                height: '100vh'
            }}>
                <InputGroup className='mb-3'>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faChildren} />
                    </InputGroup.Text>
                    <Form.Control
                        type='text'
                        placeholder='Nombre del Perfil'
                        onChange={(e) => setNameProfile(e.target.value)}
                    />
                </InputGroup>
                <InputGroup className='mb-2'>
                    <Form.Check
                        type="switch"
                        label="¿Eres Menor de Edad? &nbsp;&nbsp;"
                        className='text-light h5'
                        reverse
                        onChange={(e) => setIsChild(e.target.checked)}
                        size={30}
                    />
                </InputGroup>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    color: '#fff',
                    fontSize: 20,
                    marginBottom: 10
                }}>
                    Selecciona un Avatar
                </div>
                <div className='caja' style={{
                    display: 'flex',
                    width: '100%',
                    height: 'calc(100vh - 350px)',
                    borderRadius: 5,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    marginBottom: 20
                }}>
                    {loader ?
                        <Row className='d-flex h-100 w-100'>
                            <Col className='d-flex justify-content-center align-items-center'>
                                <Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} />
                            </Col>
                        </Row>
                        :
                        <Row>
                            {avatars.sort().map((values, index) => {
                                return (
                                    <Col key={index} xs={4} md={3} lg={2} className='mb-3'>
                                        <Image src={Helpers.API + '/cdn/' + values.filename} style={{
                                            width: '100%',
                                            height: '100%',
                                            filter: 'contrast(100%)',
                                            borderRadius: 10
                                        }}
                                            id={'img_' + values.filename.split('.')[0]}
                                            onClick={(e) => {
                                                changeAvatar(e)
                                            }}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    }
                </div>
                <Button variant='danger' className='w-100' onClick={registerProfile}>Guardar Perfil</Button>
            </div>

        </MobileLayout>
    );
}

export default NewProfile;