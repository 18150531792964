import React, { useState } from 'react';
import { Button, ButtonGroup, Alert } from 'react-bootstrap';
import MainLayout from '../../Layouts/MainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from 'react-router-dom';

function MainWeb(props) {
    
    const navigate = useNavigate();
    const [variante, setVariante] = useState('primary')
    const [alertMsg, setAlertMsg] = useState("")
    const [alertView, setAlertView] = useState(false)

    const showAlert = (variante, message) => {
        setVariante(variante)
        setAlertMsg(message)
        setAlertView(true)
        setTimeout(() => {
            setAlertView(false)
        }, 3000)
    }

    return (
        <MainLayout >
            <Alert className="fixed-top" style={{ display: alertView ? '' : 'none', textAlign: 'center', marginTop: 16, marginLeft: 16, marginRight: 16, width: 'auto' }} key={variante} variant={variante}>{alertMsg}</Alert>
            <div className='d-flex justify-content-between align-items-center' style={{ height: 50 }}>
                <div className='d-flex text-light h3 fw-bold'>Inicio</div>
                <div className='d-flex' style={{ height: 50 }}>
                    <ButtonGroup style={{ width: 200 }}>
                    <Button variant='danger' onClick={(e) => { 
                        e.preventDefault(); 
                        localStorage.removeItem('token'); navigate('/');
                        showAlert('danger', 'Hasta Pronto!!');
                    }} className='fw-bold'>
                        <FontAwesomeIcon icon={faSignOut} className='me-3 fa-1x' />Cerrar Sesión</Button>
                    </ButtonGroup>
                </div>
            </div>
            
        </MainLayout>
    );
}

export default MainWeb;