import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

//import Helpers from "../../Libs/Helpers";
import MainWeb from "./MainWeb";
import LoginWeb from "./LoginWeb";
import Tenants from "./Tenants";
import Devices from "./Devices";
import Invoices from "./Invoices";
import Entertainment from "./Entertainment";
import Activate from "./Activate";
import Brokers from "./Brokers";
import Sensors from "./Sensors";
import RMovies from "./Components/Player/RMovies";
import ViewMovie from "./Components/Preview/ViewMovie";
//import Error405 from "../../Pages/E405";

function RouteWeb(props) {

  const [token, setToken] = useState(localStorage.getItem('token'))


  React.useEffect(() => {

    setInterval(() => {
      setToken(localStorage.getItem('token'))
     /* if (localStorage.getItem('token')) {
        Helpers.queryAuth('/api/devices/one', 'POST', localStorage.getItem('token'), { id: localStorage.getItem('deviceID') }).then((data) => {
          if (data) {
            localStorage.setItem('mySelf', true)
          } else {
            localStorage.removeItem('mySelf')
          }
        })
      }*/
    }, 5000)
  }, [])

  return (
    <div>
      <Routes>
        {token && <>
          <Route path="/" element={<MainWeb />} />
          <Route path="/home" element={<MainWeb />} />
          <Route path="/tenants" element={<Tenants />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/brokers" element={<Brokers />} />
          <Route path="/sensors" element={<Sensors />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/entertainment" element={<Entertainment />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/rmovies" element={<RMovies />} />
          <Route path="/viewmovie" element={<ViewMovie />} />
        </>
        }
        <Route path="*" element={<LoginWeb />} />
      </Routes>
    </div>
  );
}

export default RouteWeb;
