import React from 'react';
import TVLayout from '../../Layouts/TVLayout'
import { Button } from 'react-bootstrap';

function ProfileTV(props) {
    return (
        <TVLayout>
            <div className='text-light fw-bold h3'>Perfiles</div>
            <Button variant='danger' onClick={(e) => {
                e.preventDefault();
                localStorage.removeItem('auth');
                window.location.href = '/';
            }}>Salir</Button>
        </TVLayout>
    );
}

export default ProfileTV;