import React from 'react';
import TVLayout from '../../Layouts/TVLayout'

function SearchTV(props) {
    return (
        <TVLayout>
            <div className='text-light fw-bold h3'>Buscador de Contenido</div>
        </TVLayout>
    );
}

export default SearchTV;