import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import MainTV from "./MainTV";
import HomeTV from "./HomeTV";
import LoadingTV from "./LoadingTV";
import MoviesTV from './MoviesTV';
import SeriesTV from './SeriesTV';
import SearchTV from './SearchTV';
import ProfileTV from "./ProfileTV";
import RMovies from "../Web/Components/Player/RMovies";
import ViewMovie from "./ViewMovie";

function RouteTV(props) {

  //const [token, setToken] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    try {
      if (!localStorage.getItem('code')) {
        localStorage.setItem('code', Math.floor(10000000 + Math.random() * 90000000));
      }
      setTimeout(() => {
        setLoading(true)
      }, 1000)
    } catch {
      console.info()
    }
  }, [])


  return (
    <div>
      <Routes>
        {
          !loading
            ?
            <Route path="/" element={<LoadingTV />} />
            : localStorage.getItem('auth')
              ?
              <>
                <Route path="/" element={<HomeTV />} />
                <Route path="/hometv" element={<HomeTV />} />
                <Route path="/moviestv" element={<MoviesTV />} />
                <Route path="/seriestv" element={<SeriesTV />} />
                <Route path="/searchtv" element={<SearchTV />} />
                <Route path="/profiletv" element={<ProfileTV />} />
                <Route path="/rmovies" element={<RMovies />} />
                <Route path="/viewmovie" element={<ViewMovie />} />
              </>
              :
              <Route path="/" element={<MainTV />} />
        }
      </Routes>
    </div>
  );
}

export default RouteTV;
