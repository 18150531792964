import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Css/Master.css";
import fpPromise from '@fingerprintjs/fingerprintjs';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { deviceType } from 'react-device-detect';
import RouteTV from "./Components/tvOS/RouteTV";
import RouteMobile from "./Components/Mobile/RouteMobile";
import RouteWeb from "./Components/Web/RouteWeb";
import RouteAdmin from "./Components/Admin/RouteAdmin";
/*
import { Peer } from 'peerjs'

const peer = new Peer('3A41B3DC-0D95-4367-B23F-3E187E17F47F', {
  host: 'clients.imesh.app',
  debug: true,
  path: '/mesh',
  secure: true
})
*/

const App = () => {

  useEffect(() => {
    if (!localStorage.getItem('deviceID')) {
      fpPromise.load().then(fp => fp.get()).then((result) => {
        localStorage.setItem('deviceID', result.visitorId)
        localStorage.setItem('deviceFabricante', result.components.vendor.value)
        localStorage.setItem('deviceModelo', result.components.platform.value)
      })
    }
  }, [])

/*
  if (deviceType === 'mobile' || deviceType === 'tablet') {
    require("./Css/Mobile.css");
  } else {
    require("./Css/Master.css");
  }
*/
  return (
    <Router>
      <Routes>
        {
          deviceType === 'browser' ?
            <>
              <Route path="*" element={<RouteWeb />} />
              <Route path="/admin/*" element={<RouteAdmin />} />
            </>
            :
            deviceType === 'mobile'
            ?
              <Route path="*" element={<RouteMobile />} />
            :
              deviceType === 'tablet'
            ?
              <Route path="*" element={<RouteMobile />} />
            :
              <Route path="*" element={<RouteTV />} />
        }
      </Routes>
    </Router>
  );
};

export default App;
