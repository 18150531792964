import React from "react";
import { Container, Button } from "react-bootstrap";
import AdminSide from "./AdminSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function MainLayout({ children }) {

  const history = useNavigate()

  return (
    <Container>
      <AdminSide />
      <div className="pt-3">
        <div className="d-flex w-100 justify-content-between align-items-center" style={{ height: '60px' }}>
          <div className="d-flex text-light fw-bold concert">NetIX</div>
          <div className="d-flex" >
            <div>
              <Button variant="danger" className="rounded-circle" style={{ width: 60, height: 60 }} onClick={(e) => { e.preventDefault(); history('/') }}>
                <FontAwesomeIcon icon={faChevronCircleLeft} className="fa-2x" />
              </Button>
            </div>
            <div className="avatar rightUser">
            </div>
          </div>
        </div>
        <hr style={{ border: "1px solid #fff" }} />
        <div style={{ width: '100%', height: '80vh', overflowX: 'hidden', overflowY: 'auto', padding: 10 }}>
          {children}
        </div>
        
      </div>
    </Container>
  );
}

export default MainLayout;
