import React, {useRef, useState} from "react";
import { Container, InputGroup, Form, Button, Row, Col, Alert } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "../../Assets/Animaciones/LoginWeb.json";
import Helpers from "../../Libs/Helpers";
import OtpInput from 'react-otp-input';

function LoginMobile(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [correo, setCorreo] = useState("")
  const [passwd, setPasswd] = useState("")
  const [otp, setOTP] = useState("")
  const [estado, setEstado] = useState(0)

  const [vmail, setVMail] = useState(true)
  const [vpwd, setVPwd] = useState(false)
  const [votp, setVOtp] = useState(false)

  const [variante, setVariante] = useState('primary')
  const [alertMsg, setAlertMsg] = useState("")
  const [alertView, setAlertView] = useState(false)
  const otpInput = useRef()

  const showAlert = (variante, message) => {
    setVariante(variante)
    setAlertMsg(message)
    setAlertView(true)
    setTimeout(() => {
      setAlertView(false)
      if(localStorage.getItem('token')){
        window.location.reload()
      }
    }, 3000)
  }

  const updEstado = async (ottp) => {
    if (estado === 0) {
      const defo0 = await Helpers.queryNoAuth('/api/users/getmail', 'POST', { email: correo })
      if (defo0.code === 1) {
        setVMail(false)
        setVPwd(true)
        setEstado(1)
      } else {
        showAlert('danger', 'Correo Incorrecto, Intente Nuevamente!')
        setEstado(0)
        setVMail(true)
      }
    } else if (estado === 1) {
      const defo1 = await Helpers.queryNoAuth('/api/users/verifypass', 'POST', { email: correo, password: passwd })
      if (defo1.code !== 0) {
        if (defo1.code.a2f === 1) {
          localStorage.setItem("base32", defo1.code.base32);
          setVPwd(false)
          setVOtp(true)
          setOTP("")
          setEstado(2)
        } else {
          localStorage.setItem("base32", defo1.code.base32);
          setVPwd(false)
          setVOtp(true)
          setEstado(2)
          otpInput.current.focus()
        }
      } else {
        showAlert('danger', 'Contraseña Incorrecta, Intente Nuevamente!')
        setVPwd(true)
      }
    } else if (estado === 2) {
      const defo2 = await Helpers.queryNoAuth('/api/users/loginotp', 'POST', { email: correo, password: passwd, otp: ottp })
      if (defo2) {
        await Helpers.queryAuth('/api/users/upd', 'POST', defo2.token, { id: defo2.id, a2f: 1 })
        localStorage.setItem('a2f', defo2.a2f)
        localStorage.setItem('base32', defo2.base32)
        localStorage.setItem('cellphone', defo2.cellphone)
        localStorage.setItem('email', defo2.email)
        localStorage.setItem('id', defo2.id)
        localStorage.setItem('lastnames', defo2.lastnames)
        localStorage.setItem('names', defo2.names)
        localStorage.setItem('status', defo2.status)
        localStorage.setItem('token', defo2.token)
        localStorage.setItem('roll', defo2.roll)
        showAlert('success', `Hola ${defo2.names} Bienvenido Nuevamente!!!`)
      } else {
        showAlert('danger', 'Código Incorrecto, Intente Nuevamente!')
        setVOtp(true)
        setOTP("")
      }
    }
  }

  const onChangeOTP = (e) => {
    console.log(e)
    setOTP(e)
    if(e.length === 6){
      setOTP(e)
      updEstado(e)
      
    }
  }

  
  
  return (
    <div>
      <Container className="loginmobile">
          <Alert className="fixed-top" style={{ display: alertView ? '' : 'none', textAlign: 'center', marginTop: 16, marginLeft: 16, marginRight: 16, width: 'auto' }} key={variante} variant={variante}>{alertMsg}</Alert>
          <Row style={{ height: '50vh' }}>
            <Col sm={12} style={{ height: '100%', display: 'flex', justifyContent: 'end', alignItems: 'start', flexDirection: 'column', padding: 30 }}>
              <div className="logo">NetIX</div>
              <div className="subtitle">
                Comunidad de Emprendimiento y Transformación Dígital
              </div>
              <div className="formulario" style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}>
                <InputGroup className="maxInput" style={{ display: !vmail ? 'none' : '' }}>
                  <Form.Control
                    placeholder="Correo Electrónico"
                    aria-label="Correo Electrónico"
                    aria-describedby="mycorreo"
                    autoFocus={true}
                    defaultValue={correo}
                    onChange={(event) => { setCorreo(event.target.value) }}
                    onKeyDown={(e) => { if (e.key === 'Enter') { updEstado() } }}
                    style={{ fontSize: 22, fontWeight: 'bold' }}
                  />
                  <Button variant="danger" id="button-addon2" className="buttonl" onClick={updEstado}>
                    Continuar
                  </Button>
                </InputGroup>
                <InputGroup className="maxInput" style={{ display: !vpwd ? 'none' : '', width: 'auto' }}>
                  <Form.Control
                    placeholder="Contraseña"
                    aria-label="Contraseña"
                    aria-describedby="mypasswd"
                    type="password"
                    autoComplete="current-password"
                    autoFocus={true}
                    value={passwd}
                    style={{ visibility: !vpwd ? 'hidden' : 'visible', fontSize: 22, fontWeight: 'bold' }}
                    onChange={(event) => { setPasswd(event.target.value) }}
                    onKeyDown={(e) => { if (e.key === 'Enter') { updEstado() } }}
                  />
                  <Button variant="danger" id="button-addon2" className="buttonl" onClick={updEstado}>
                    Continuar
                  </Button>
                </InputGroup>
                <InputGroup className="maxInput" style={{ display: !votp ? 'none' : '', width: 'auto' }}>
                  <OtpInput
                    ref={otpInput}
                    value={otp}
                    onChange={(e) => { onChangeOTP(e)}}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus={true}
                    inputType="number"
                    autoFocus={true}
                    inputStyle={{
                      fontSize: 22,
                      width: 50,
                      height: 60,
                      borderRadius: 5
                    }}
                    
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row style={{ height: '50vh' }}>
            <Col sm={12} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Lottie
                className="d-flex"
                options={defaultOptions}
                height={280}
                width={280}
              />
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default LoginMobile;
