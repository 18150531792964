import React, { useEffect, useState } from 'react';
import MainLayout from '../../../../Layouts/MainLayout';
import Helpers from '../../../../Libs/Helpers';
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom"

function ViewMovie(props) {

    const history = useNavigate()
    const [curt, setCurt] = useState()

    useEffect(() => {
        if(!curt){
        if (JSON.parse(localStorage.getItem('movie')).progress.length > 0) {
                setCurt(JSON.parse(localStorage.getItem('movie')).progress[0].currenttime)
            } else {
                setCurt(0)
            }
        }
    }, [curt])

    return (
        <MainLayout>
            <div
                style={{
                    borderRadius: 10,
                    width: '100%',
                    height: '70vh',
                    backgroundImage: `url(${Helpers.API}/cdn/background_${JSON.parse(localStorage.getItem('movie'))._id}.${JSON.parse(localStorage.getItem('movie')).background_extension})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    filter: 'contrast(100%)'
                }}
            >
                <Row style={{
                    backgroundColor: 'rgba(20,30,40,0.5)',
                    height: '100%',
                    width: '100%',
                    marginLeft: 0,
                    padding: 20,
                    borderRadius: 10
                }}>
                    <Col sm={6} className='d-flex justify-content-between align-items-start flex-column h-auto'>
                        <div className='d-flex flex-column' style={{ height: 150, width: '100%' }}>
                            <div className='d-flex' style={{ textShadow: '1px 1px 1px #000', color: '#fff', fontSize: 40, fontWeight: 'bold' }}>{JSON.parse(localStorage.getItem('movie')).name}</div>
                            <div className='d-flex' style={{ textShadow: '1px 1px 1px #000', color: '#fff', fontSize: 20, fontWeight: 'bold' }}>{JSON.parse(localStorage.getItem('movie')).producer[0].name}</div>
                        </div>
                        <div
                            className='d-flex rounded-pill shadow-sm p-3 mt-5 w-100 justify-content-start align-items-center'
                            style={{ backgroundColor: 'rgba(255,255,255,0.4)' }}
                        >
                            <div className='d-flex'>
                                <Button className="rounded-pill" variant='dark'
                                    style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history('/rmovies')
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlay} className='d-flex fa-2x' style={{ marginLeft: 4 }} />
                                </Button>
                            </div>
                            <div className='d-flex ms-3'>
                                <Button className="rounded-pill" variant='dark'
                                    style={{ width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurt(0);
                                        history('/rmovies');
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRotate} className='d-flex fa-2x' />
                                </Button>
                            </div>
                            <div className='d-flex ms-3' style={{ width: '74%' }}>
                                <input type='range' value={curt} defaultValue={curt} min={0} max={Helpers.toSeconds(JSON.parse(localStorage.getItem('movie')).duration)} step={1} disabled={true} />
                            </div>
                        </div>

                    </Col>
                    <Col sm={6} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', height: '100%' }}>
                        <div className="viewMovie">
                            <div style={{ fontSize: 30 }}>Sinopsis:</div>
                            {JSON.parse(localStorage.getItem('movie')).description}
                            <br /><br />
                            Duración: {JSON.parse(localStorage.getItem('movie')).duration}
                            <br />
                            {JSON.parse(localStorage.getItem('movie')).genders}
                            <br /><br />
                            Clasificación: {JSON.parse(localStorage.getItem('movie')).classification}+
                        </div>
                    </Col>
                </Row>


            </div>
        </MainLayout >
    );
}

export default ViewMovie;