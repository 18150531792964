import React from 'react';
import { useNavigate } from "react-router-dom";
import Helpers from "../Libs/Helpers";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function E405(props) {

    const navigate = new useNavigate()

    React.useEffect(() => {
        setInterval(() => {
            Helpers.validate(navigate)
        }, 1000)
    }, [navigate])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <section style={{
                display: 'flex',
                fontFamily: 'Concert',
                fontSize: 42,
                color: '#fff',
                textAlign: 'center'
            }}>NetIX</section>
            <div style={{ display: 'flex', color: '#fff', textAlign: 'center', padding: 30, textTransform: 'uppercase', fontWeight: 'bold' }}>La página que Buscas no está disponible</div>
            <div>
                <Button onClick={(e) => {
                    e.preventDefault();
                    navigate('/')
                }}
                    variant='danger'
                >
                    <FontAwesomeIcon icon={faArrowLeft} className='fa-fw me-2' />Inicio</Button>
            </div>
        </div>
    );
}

export default E405;