import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap'
import Helpers from '../../Libs/Helpers';

function MovieCard(props) {

    const [imagen, setImagen] = useState()
    useEffect(()=>{
        setImagen(Helpers.API + '/cdn/poster_' + props.id_title + '.' + props.extension)
    },[props, imagen])

    return (
        <Card className='bg-dark mb-4 shadow-sm'>
            <Card.Body className='rounded p-0'>
                <div style={{ 
                    width: '100%', 
                    height: '280px', 
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${imagen})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }} className='rounded' />
            </Card.Body>
        </Card>
    );
}

/*
<Card.Header className='fw-bold text-center text-light h5'>{props.name}</Card.Header>
            <Card.Footer className='p-1'></Card.Footer>
            
*/
export default MovieCard;