import React from "react";
import { Button, Container } from "react-bootstrap";
import Side from "./Side";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function MainLayout({ children }) {

  const history = useNavigate()

  return (
    <Container>
      <Side />
      <div className="pt-3">
        <div className="d-flex w-100 justify-content-between align-items-center" style={{ height: '60px' }}>
          <div className="d-flex text-light fw-bold concert">NetIX</div>
          <div className="d-flex" >
            {parseInt(localStorage.getItem('roll')) === 0 && <Button variant="danger" className="rounded-circle" style={{ width: 60 }} onClick={(e) => { e.preventDefault(); history('/admin/') }}>
              <FontAwesomeIcon icon={faCog} className="fa-2x" />
              </Button>}
            <div className="rightUser avatar"></div>
          </div>
        </div>
        <hr style={{ border: "1px solid #fff" }} />
        <div className="caja-show" style={{ width: '100%', height: 'calc(100vh - 130px)', overflowX: 'hidden', overflowY: 'auto', padding: 10 }}>
          {children}
        </div>
      </div>
    </Container>
  );
}

export default MainLayout;
