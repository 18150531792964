import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


function Brokers(props) {

    const history = useNavigate()

    return (
        <AdminLayout>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex'>
                    <div className='text-light fw-bold h3'>Gestión de Start-Brokers</div>
                </div>
                <div className='d-flex'>
                    <Button variant='danger' onClick={(e) => { e.preventDefault(); history('/admin/newbroker') }}>
                        <FontAwesomeIcon icon={faServer} className='me-3' />
                        Nuevo Broker
                    </Button>
                </div>
            </div>
            
        </AdminLayout>
    );
}

export default Brokers;