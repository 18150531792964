//import { faPlay } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function WebMovieCard(props) {

    const history = useNavigate()
    const [selfMovie, setSelfMovie] = useState([])

    useEffect(()=>{
        setSelfMovie(props.datos)
    },[props])

    const loadMovie = (e) => {
        e.preventDefault();
        localStorage.setItem('movie', JSON.stringify(selfMovie))
        history('/viewmovie')
    }

    return (
        <Card className='border-0 mb-3' >
            <Card.Body className='p-0'>
                <Button variant='danger' className='shadow-sm marvel' style={{
                    width: '100%',
                    height: '260px',
                    borderRadius: 5,
                    backgroundSize: 'cover',
                    backgroundImage: `url(${props.poster})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    border: '0px solid #000',
                }} onClick={(e) => {
                    loadMovie(e)
                }}>
                </Button>
            </Card.Body>
        </Card>
    );
}

export default WebMovieCard;