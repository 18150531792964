import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function Sensors(props) {
    return (
        <MainLayout>
            <div className='text-light fw-bold h3 mb-5'>
                Sensores IoT
            </div>
            <Alert variant='warning'>
                <FontAwesomeIcon icon={faTriangleExclamation} className='me-3' />
                Próximamente todo el Poder del IoT en tus Manos!!</Alert>
        </MainLayout>
    );
}

export default Sensors;