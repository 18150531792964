import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'

function LoadingTV(props) {
    return (
        <Container fluid className='vw-100 vh-100'>
            <Row className='w-100 h-100'>
                <Col className='d-flex justify-content-center align-items-center'>
                    <Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} />
                </Col>
            </Row>
        </Container>
    );
}

export default LoadingTV;