import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainAdmin from "./MainAdmin";
import Movies from "./Movies";
import Series from "./Series";
import Brokers from "./Brokers";
import NewMovie from "./NewMovie";
import NewBroker from "./NewBroker";
import AdminSettings from "./Producers";
import NewProducers from "./NewProducers";

function RouteAdmin(props) {

  return (
    <div>
      <Routes>
        {parseInt(localStorage.getItem('roll')) === 0 ? <>
          <Route path="/" element={<MainAdmin />} />
          <Route path="/home" element={<MainAdmin />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/newmovie" element={<NewMovie />} />
          <Route path="/series" element={<Series />} />
          <Route path="/brokers" element={<Brokers />} />
          <Route path="/newbroker" element={<NewBroker />} />
          <Route path="/producers" element={<AdminSettings />} />
          <Route path="/newproducer" element={<NewProducers />} />
        </>
          :
          <Route path="*" element={<Navigate replace to="/" />} />
        }
      </Routes>
    </div>
  );
}

export default RouteAdmin;
