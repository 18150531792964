import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout'

function Series(props) {
    return (
        <AdminLayout>
            <div className='text-light fw-bold h3'>Gestión de Series</div>
        </AdminLayout>
    );
}

export default Series;