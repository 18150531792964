import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import { Button, ButtonGroup, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlugCircleCheck, faPlus, faTrash, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import Helpers from '../../Libs/Helpers';

function Devices(props) {

    const navigate = new useNavigate()
    const [devicesUp, setDevicesUp] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [devices, setDevices] = React.useState([])

    React.useEffect(() => {
        Helpers.queryAuth('/api/devices/count', 'POST', localStorage.getItem('token'), { id: localStorage.getItem('id') }).then((data) => {
            setDevicesUp(data)
            setLoading(true)
        })
        Helpers.queryAuth('/api/devices/', 'POST', localStorage.getItem('token'), { id: localStorage.getItem('id') }).then(data => setDevices(data))
    }, [])

    return (
        <MainLayout>
            {!loading ? <div className='d-flex justify-content-center align-items-center w-100 vh-75 mt-5'><Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} /></div> : <>
                <div className='d-flex justify-content-between align-items-center mb-3' style={{ height: 50 }}>
                    <div className='text-light h3 fw-bold'>Dispositivos ({devicesUp}/5)</div>
                    <div className='d-flex'>
                        <ButtonGroup style={{ width: 400, height: 50 }}>
                            <Button variant='outline-danger' onClick={(e => { e.preventDefault(); navigate('/newdevice') })} className='d-flex justify-content-center align-items-center fw-bold'>
                                <FontAwesomeIcon icon={faPlus} className='me-3 fa-1x' />Agregar</Button>
                            <Button variant='outline-danger' onClick={(e => { e.preventDefault(); navigate('/activate') })} className='d-flex justify-content-center align-items-center fw-bold'>
                                <FontAwesomeIcon icon={faPlugCircleCheck} className='me-3 fa-1x' />Activar</Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div>
                    <Table striped hover variant='light' style={{ borderRadius: 20 }}>
                        <thead>
                            <tr>
                                <th>Dispositivo</th>
                                <th>Fabricante</th>
                                <th>Modelo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        { 
                            devices.map((values, index) => { 
                                return (
                                <tr key={index}>
                                    <td valign='middle'>{values.device_id}</td>
                                    <td valign='middle'>{values.fabricante}</td>
                                    <td valign='middle'>{values.modelo}</td>
                                    <td valign='middle'>
                                        <ButtonGroup className='w-100'>
                                            <Button variant='warning'><FontAwesomeIcon icon={faVideoSlash} className='me-2' />Desactivar</Button>
                                            <Button variant='danger'><FontAwesomeIcon icon={faTrash} className='me-2' />Eliminar</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                                );
                            })
                        }
                            
                        </tbody>
                    </Table>
                </div>
            </>
            }
        </MainLayout>
    );
}

export default Devices;