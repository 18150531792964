import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from 'react-bootstrap'
import Helpers from "../../../../Libs/Helpers";
import { useNavigate } from 'react-router-dom'

function MainCarrousel(props) {

    const history = useNavigate()

    const settings = {
        className: 'center',
        centerMode: false,
        fade: false,
        lazyLoad: false,
        adaptiveHeight: true,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: props.autoplay,
        autoplaySpeed: 3000,
        cssEase: "linear",
        waitForAnimate: true
    };

    return (
        <div className="slider-container">
            <div className="text-light h5">{props.module}</div>
            <Slider {...settings}>
                { props.movies.map((values, index)=>{
                    return (
                        <div key={index}>
                            <div style={{ 
                                position: 'fixed', 
                                bottom: 20, 
                                paddingLeft: 20, 
                                paddingRight: 20,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginLeft: 20,
                                borderRadius: 10,
                                width: 'auto', 
                                backgroundColor: 'rgba(0,0,0,0.4)',
                                color: '#fff'
                            }}>{values.name}</div>
                            <Image src={`${Helpers.API}/cdn/thumbnail_${values._id}.${values.thumbnail_extension}`} style={{ border: 0,  height: 200, width: '95%', borderRadius: 10, marginLeft: 5, cursor: 'pointer' }}
                            onClick={(e)=> {
                                e.preventDefault();
                                localStorage.setItem('movie', JSON.stringify(values))
                                history('/viewmovie')
                            }}
                            />
                        </div>
                    )
                })}
            </Slider>
        </div>
    );
}

export default MainCarrousel;