import React, { useEffect } from "react";
import TVSide from "./TVSide";
import { SmartTVView } from 'react-device-detect'

function MainLayout({ children }) {

  useEffect(() => {
    if (!localStorage.getItem('auth')) {
      window.location.href = '/'
    }
  }, [])

  return (
    <SmartTVView>
      <TVSide />
      <div className="pt-3" style={{ width: '85%', marginLeft: '13%' }}>
        <div className="d-flex w-100 justify-content-between align-items-center" style={{ height: '60px' }}>
          <div className="d-flex text-light fw-bold concert">NetIX</div>
        </div>
        <hr style={{ border: "1px solid #fff" }} />
        <div style={{ width: '100%', height: '80vh', overflowX: 'hidden', overflowY: 'auto', padding: 10 }}>
          {children}
        </div>

      </div>
    </SmartTVView>
  );
}

export default MainLayout;
