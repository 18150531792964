import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Helpers from '../../Libs/Helpers';

function MainTV(props) {

    const code = localStorage.getItem('code');
    const digits = code.toString().split('');

    useEffect(() => {
        setInterval(() => {
            if (!localStorage.getItem('auth')) {
                    Helpers.queryNoAuth('/api/devices/predevice', 'POST', {
                        code: code,
                        device_id: localStorage.getItem('deviceID'),
                        fabricante: localStorage.getItem('deviceFabricante'),
                        modelo: localStorage.getItem('deviceModelo')
                    }).then((data) => {
                        if (data.auth) {
                            localStorage.setItem('auth', data.auth)
                            window.location.reload();
                        } else {
                            console.log('Esperando....')
                        }
                    }).catch((err)=>{
                        console.info(err)
                    })
            }
        }, 3000)
    }, [code])

    return (
        <Container className='fixed-bottom maintv'>
            <Row className='d-flex justify-content-center maintv'>
                <Col sm={7} className='mb-5'>
                    <div className='h1 text-light'>Iniciar sesión en línea</div>
                    <div className='mt-5 h4 text-light'>Para continuar, visita el siguiente enlace en una computadora o dispositivo móvil.</div>
                    <div className='mt-5 h4 text-light fw-bold'>netix.app/activate</div>
                    <div className='mt-5 border border-3 border-white p-3 h1 text-light bg-transparent text-center rounded fw-bold' style={{ width: '300px' }}>
                        {`${digits[0]}${digits[1]}${digits[2]}${digits[3]}-${digits[4]}${digits[5]}${digits[6]}${digits[7]}`}
                    </div>
                    <div className='mt-5 text-light h5'>Al completar todos los pasos el dispositivo se configurará automáticamente.</div>
                </Col>
                <Col sm={5}>
                    <div className='w-100 telefono'>
                        <div className='toper'>
                            <div className='camera'></div>
                            <div className='speaker'></div>
                        </div>
                        <div className='display'>
                            <div className='header'>
                                <div className='browser'>netix.app/activate</div>
                            </div>
                            <div className='page'>
                                <div className='logo'>NetIX</div>
                                <div className='subtitle'>Comunidad de Emprendimiento y Transformación Dígital</div>
                                <div className='digit'>{digits[0]}</div>
                                <div className='digit'>{digits[1]}</div>
                                <div className='digit'>{digits[2]}</div>
                                <div className='digit'>{digits[3]}</div>
                                <div className='digit'>{digits[4]}</div>
                                <div className='digit'>{digits[5]}</div>
                                <div className='digit'>{digits[6]}</div>
                                <div className='digit'>{digits[7]}</div>
                                <hr className='borde' />
                                <div className='boton'>Activar Dispositivo</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MainTV;