import React, { useEffect, useState } from 'react';
import Hls from '@tianfeng98/hls.js'
import Helpers from '../../Libs/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faExpand, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { isIOS } from 'react-device-detect';

function Preview(props) {
    const player = React.useRef()
    const [curt, setCurt] = useState()
    const [code, setCode] = useState()
    const [hlsX, setHlsX] = React.useState(null)
    const history = useNavigate();
    const [loading, setLoading] = useState(false)
    const autoPlay = false
    const [isPlaying, setIsPlaying] = useState(false)


    useEffect(function () {

        setCode(JSON.parse(localStorage.getItem('movie')).movie[0].code)
        if (code) {
            if (JSON.parse(localStorage.getItem('movie')).progress.length > 0) {
                setCurt(JSON.parse(localStorage.getItem('movie')).progress[0].currenttime)
            } else {
                setCurt(0)
            }
            const src = `https://brokers.veemind.uk/10.8.0.10/${code}/playlist.m3u8`
            const hlsConfig = {
                autoStartLoad: true,
                debug: false,
                recover: -1,
                initialLiveManifestSize: 1,
                maxBufferSize: 10 * 1024 * 1024,
                maxBufferLength: 120,
                maxMaxBufferLength: 240,
                backBufferLength: 60,
                maxBufferHole: 0.5,
                highBufferWatchdogPeriod: 2,
                nudgeOffset: 0.1,
                nudgeMaxRetry: 3,
                maxFragLookUpTolerance: 0.25,
                liveSyncDurationCount: 3,
                overrideNative: true,
                liveMaxLatencyDurationCount: Infinity,
                liveDurationInfinity: false,
                preferManagedMediaSource: true,
                fragLoadPolicy: {
                    default: {
                        maxTimeToFirstByteMs: 9000,
                        maxLoadTimeMs: 100000,
                        timeoutRetry: {
                            maxNumRetry: 2,
                            retryDelayMs: 0,
                            maxRetryDelayMs: 0,
                        },
                        errorRetry: {
                            maxNumRetry: 5,
                            retryDelayMs: 3000,
                            maxRetryDelayMs: 15000,
                            backoff: 'linear',
                        },
                    },
                },
                startFragPrefetch: false,
                testBandwidth: false,
                progressive: false,
                lowLatencyMode: true,
                nextLoadLevel: 0,
                fpsDroppedMonitoringPeriod: 5000,
                fpsDroppedMonitoringThreshold: 0.2,
                appendErrorMaxRetry: 3,
                enableWebVTT: true,
                xhrSetup: xhr => {
                    xhr.setRequestHeader('Authorization', `Bearer: ${localStorage.getItem('token')}`)
                }
            }
            if (Hls.isSupported()) {
                const hls = new Hls(hlsConfig)
                hls.loadSource(src)
                hls.attachMedia(player.current)
                hls.currentLevel = 0
                hls.audioTrack = 0
                hls.subtitleTrack = -1
                setHlsX(hls)

            }
        }

        player.current.addEventListener("canplay", () => {
            setLoading(true)
        })

        player.current.addEventListener('playing', () => {
            setIsPlaying(true)
        })

        player.current.addEventListener('pause', () => {
            setIsPlaying(false)
        })

        
    }, [player, code]);



    return (
        <div style={{
            justifyContent: 'start',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        }}
        >
            {!loading &&
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100vw',
                    height: '100vh',
                    zIndex: 1000,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                className='mongomery'
                >
                    <Spinner variant='danger' animation="border" style={{ display: 'flex', height: 150, width: 150 }} />
                </div>
            }
            <div style={{
                paddingLeft: 20,
                paddingRight: 20,
                color: '#fff',
                width: '100%',
                height: 80,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <section style={{
                    display: 'flex'
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        history(-1)
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} size='2x' color={'white'} />
                </section>
                <section style={{
                    fontFamily: 'Concert',
                    fontSize: 42,
                    display: 'flex'
                }}>NetIX</section>

            </div>
            <div hls={hlsX} className='rep_container_mobile'>
                <video
                    ref={player}
                    autoPlay={autoPlay}
                    playsInline={true}
                    preload='metadata'
                    controls={false}
                    controlsList="nodownload noplaybackrate"
                    disableRemotePlayback
                    disablePictureInPicture
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                    currenttime={curt}
                    poster={Helpers.API + "/cdn/thumbnail_" + JSON.parse(localStorage.getItem('movie'))._id + "." + JSON.parse(localStorage.getItem('movie')).thumbnail_extension}
                />
                <div style={{
                    zIndex: 10,

                }}>

                </div>
            </div>
            <div style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>
                <ButtonGroup style={{
                    width: '90%'
                }}>
                    <Button variant='danger' onClick={(e) => {
                        e.preventDefault();
                        isPlaying ? player.current.pause() : player.current.play();
                    }}><FontAwesomeIcon icon={isPlaying ? faPause : faPlay} color={'white'} /></Button>
                    <Button variant='danger' onClick={(e)=>{
                        e.preventDefault();
                        if(isIOS){
                            player.current.webkitEnterFullscreen();
                        } else {
                            player.current.requestFullscreen();
                        }
                    }}><FontAwesomeIcon icon={faExpand} color={'white'} /></Button>
                </ButtonGroup>
            </div>
            <div style={{
                color: '#fff',
                padding: 10,
                textAlign: 'justify'
            }}>
                {JSON.parse(localStorage.getItem('movie')).description}
            </div>
        </div>
    );
}

export default Preview;