import React from 'react';
import { Button } from 'react-bootstrap';

function MobileLayout({children}) {


    return (
        <div style={{
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            padding: 20,
        }}>
            <div style={{
                color: '#fff',
                width: '90%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <section style={{
                    fontFamily: 'Concert',
                    fontSize: 42,
                }}>NetIX</section>
                <section style={{
                }}>
                    <Button variant='danger' onClick={(e) => {
                        e.preventDefault();
                        localStorage.removeItem('token')
                        localStorage.removeItem('profile')
                        window.location.href = '/'
                    }}>Salir</Button>
                </section>
            </div>
            <div style={{
                width: '100%',
            }}>
                <hr style={{
                    color: '#f00',
                    height: 1,
                    borderWidth: 4,
                    borderRadius: 5
                }} />
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}

export default MobileLayout;