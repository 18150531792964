import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function Tenants(props) {

    const navigate = new useNavigate()

    return (
        <MainLayout>
            <div className='d-flex justify-content-between align-items-center' style={{ height: 50 }}>
                <div className='text-light h3 fw-bold'>Tenants</div>
                <div className='d-flex'>
                    <ButtonGroup style={{ width: 200, height: 50 }}>
                        <Button variant='danger' onClick={(e => { e.preventDefault(); navigate('/newdevice') })} className='d-flex justify-content-center align-items-center fw-bold'>
                            <FontAwesomeIcon icon={faPlus} className='me-3 fa-1x' />Nuevo Tenant</Button>
                        
                    </ButtonGroup>
                </div>
            </div>
        </MainLayout>
    );
}

export default Tenants;