import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import MainMobile from './MainMobile'
import LoginMobile from './LoginMobile';
import NewProfile from './NewProfile';
import LoadingMobile from './LoadingMobile';
import E405 from '../../Pages/E405';
import Preview from './Preview';

function RouteMobile(props) {

  const [loading, setLoading] = useState()

  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 1000)
  }, [])

  return (
    <div>
      <Routes>
        {
          !loading
            ?
            <Route path="/" element={<LoadingMobile />} />
            :
            localStorage.getItem('token') ?
              <>
                <Route path="/" element={<MainMobile />} />
                <Route path="/newprofile" element={<NewProfile />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="*" element={<E405 />} />
              </>
              :
              <>
                <Route path="/" element={<LoginMobile />} />
                <Route path="*" element={<E405 />} />
              </>
        }
      </Routes>
    </div>
  );
}

export default RouteMobile;