import React, { useState } from 'react';
import MainLayout from '../../Layouts/MainLayout';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faPlugCircleCheck } from '@fortawesome/free-solid-svg-icons'
//import Lottie from "react-lottie";
//import animationData from "../../Assets/Animaciones/Entertainment.json";
import { Spinner, Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import Helpers from '../../Libs/Helpers';
import WebMovieCard from './Components/Movies/WebMovieCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import MainCarrousel from './Components/Carrousel/MainCarrousel';


function Entertainment(props) {

    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState('')
    const [movies, setMovies] = useState([])
    const [showMax, setShowMax] = useState(0)

    React.useEffect(() => {
        Helpers.queryAuth('/api/movies/allmovies', 'POST', localStorage.getItem('token'), { id_user: localStorage.getItem('id') }).then((data) => {
            setMovies(data)
            setLoading(true)
        })
    }, [])

    return (
        <div>
            <MainLayout>
            <div className='d-flex justify-content-between align-items-center' style={{ height: 50 }}>
                <div className='d-flex text-light h3 fw-bold'>Entretenimiento</div>
                <div className="d-flex">
                    <InputGroup>
                        <Form.Control type="text" placeholder='Buscar' onChange={(e) => { setFiltro(e.target.value); setShowMax(12) }} />
                        <Button variant='danger'>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </InputGroup>
                </div>
            </div>
            
            {!loading ? <div style={{ width: '100%', textAlign: 'center' }}><Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} /></div>
                :
                <>
                    <div className='d-flex justify-content-center align-items-center p-0' style={{ height: 300 }}>
                        <MainCarrousel className="d-flex w-100" autoplay={true} movies={movies} module="Agregados Recientemente" />
                    </div>
                    <Row className='mb-4'>
                        {movies.filter(item => item.name.includes(filtro)).slice(0, showMax).map((values, index) => {
                            return (
                                <Col key={index} sm={2}>
                                    <WebMovieCard datos={values} poster={Helpers.API + '/cdn/poster_' + values._id + '.' + values.poster_extension} />
                                </Col>
                            )
                        })}
                    </Row>
                </>
            }
                {movies.length > showMax && <div className='w-100 d-flex justify-content-center align-items-center'>
                <div className='d-flex'>
                    <Button variant='danger' className='shadow-sm' style={{ width: 80, height: 80, borderRadius: 100 }}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowMax(showMax + 12)
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='fa-2x' />
                    </Button>
                </div> 
                </div>
                   }

            </MainLayout>
            </div>
    );
}

/*

<Alert className="fixed-top" style={{ display: alertView ? '' : 'none', textAlign: 'center', marginTop: 16, marginLeft: 16, marginRight: 16, width: 'auto' }} key={variante} variant={variante}>{alertMsg}</Alert>


            : ! mySelf ? <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <div className='mt-2 h5 text-light p-3'>
                        Para poder disfrutar de todo el contenido multimedia es necesario registrar este dispositivo.
                        <br /><br />
                        <div id='box' className='fw-bold'>
                            Fabricante: {localStorage.getItem('deviceFabricante')}
                            <br />
                            Modelo: {localStorage.getItem('deviceModelo')}
                            <br />
                            DeviceID: {localStorage.getItem('deviceID')}
                        </div>
                        <br /><br />
                        <Button variant='danger' style={{ height: 50 }} onClick={(e) => { addMySelf(e) }}><FontAwesomeIcon icon={faPlugCircleCheck} className='me-3' />Agregar este Dispositivo</Button>
                    </div>

                </div>
                <div className='d-flex'>
                    <Lottie
                        options={defaultOptions}
                        height={340}
                        width={470}
                    />
                </div>
            </div>
            */

            export default Entertainment;