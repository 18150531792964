import { faPlus, faRightLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MobileLayout from '../../Layouts/MobileLayout';
import Helpers from '../../Libs/Helpers';

function MainMobile(props) {

    const history = useNavigate()
    const [profiles, setProfiles] = useState([])
    const [producers, setProducers] = useState([])
    const [movies, setMovies] = useState([])
    

    useEffect(() => {
        Helpers.queryAuth('/api/profiles/', 'POST', localStorage.getItem('token'), { id_user: localStorage.getItem('id') })
            .then((data) => {
                setProfiles(data)
            })
        Helpers.queryAuth('/api/producers/', 'POST', localStorage.getItem('token'), {})
            .then((data) => {
                setProducers(data)
            })
        Helpers.queryAuth('/api/movies/recomended', 'POST', localStorage.getItem('token'), { id_user: localStorage.getItem('id') }).then((data) => {
            setMovies(data)
        })
    }, [])

    return (
        <>
            {localStorage.getItem('profile') ?
                <>
                    <div className='text-light p-2 bg-dark d-flex justify-content-between align-items-center'>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                width: 45,
                                height: 45,
                                backgroundImage: `url("${Helpers.API + '/cdn/' + JSON.parse(localStorage.getItem('profile')).avatar}")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: 5,
                                marginRight: 10
                            }}></div>
                            <div className='text-uppercase text-start'>{JSON.parse(localStorage.getItem('profile')).name}</div>
                        </div>
                        <ButtonGroup>
                            <Button variant='dark'>
                                <FontAwesomeIcon icon={faSearch} className='fa-fw' />
                            </Button>
                            <Button variant='dark' onClick={(e) => {
                                e.preventDefault();
                                localStorage.removeItem('profile')
                                history('/')
                            }}>
                                <FontAwesomeIcon icon={faRightLeft} className='fa-fw' />
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div style={{
                        width: '100%',
                        height: 110,
                        overflowY: 'hidden',
                        overflowX: 'auto',
                        flexDirection: 'row',
                        display: 'flex',
                        paddingLeft: 10,
                        paddingTop: 10
                    }}>
                        {producers.map((values, index) => {
                            return (
                                <Button key={index} className='bg-white border-white' style={{
                                    height: 80,
                                    marginRight: 10
                                }}
                                >
                                    <Image src={Helpers.API + '/cdn/' + values._id + '.png'} style={{
                                        width: 60,
                                        height: 'auto'
                                    }} />
                                </Button>
                            )
                        })}
                    </div>
                    <div style={{
                        paddingLeft: 10,
                    }}>
                        <div style={{
                            color: '#fff',
                            fontSize: 16,
                            fontWeight: 'bold',
                            width: '100%'
                        }}>Últimas Novedades</div>
                        <div style={{
                            width: '100%',
                            height: 140,
                            overflowY: 'hidden',
                            overflowX: 'auto',
                            flexDirection: 'row',
                            display: 'flex',
                        }}>
                            {movies.map((values, index) => {
                                return (
                                    <Button variant='transparent' key={index} style={{
                                        marginRight: 10,
                                        padding: 0,
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        border: '0px solid rgba(0,0,0,0)'
                                    }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem('movie', JSON.stringify(values));
                                            history('/preview')
                                        }}
                                    >
                                        <div 
                                            style={{
                                                width: 190,
                                                height: 120,
                                                borderRadius:10,
                                                backgroundImage: `url("${Helpers.API + '/cdn/thumbnail_' + values._id + '.' + values.thumbnail_extension}")`,
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'end'
                                            }}
                                        >
                                            <div style={{
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                                width: '100%',
                                                borderBottomLeftRadius: 10,
                                                borderBottomRightRadius: 10,
                                                color: '#fff',
                                                fontSize: 10,
                                                fontWeight: 'bold',
                                                padding: 5,
                                            }}>{values.name}</div>
                                        </div>
                                    </Button>
                                )
                            })}
                        </div>
                    </div>
                </>
                :
                <MobileLayout>
                    <Row>
                        <Col>
                            <div className='d-flex h2 fw-bold text-light'>Selecciona un Perfil</div>
                        </Col>
                    </Row>
                    <Row style={{
                        height: 'calc(100vh - 180px)',
                        width: '90vw',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                        {profiles.map((values, index) => {
                            return (
                                <Col key={index} xs={4} md={3} lg={3}>
                                    <div style={{
                                        width: '100%',
                                        height: '18vh',
                                        borderRadius: 10,
                                        filter: 'contrast(100%)',
                                        backgroundImage: `url("${Helpers.API}/cdn/${values.avatar}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        marginBottom: 10,
                                        justifyContent: 'center',
                                        alignItems: 'end',
                                        display: 'flex',
                                    }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem('profile', JSON.stringify(values));
                                            history('/')
                                        }}>
                                        <div style={{
                                            width: '100%',
                                            backgroundColor: 'rgba(0,0,0,0.5)',
                                            borderBottomRightRadius: 10,
                                            borderBottomLeftRadius: 10,
                                            textTransform: 'uppercase',
                                            textAlign: 'center',
                                            fontSize: 14,
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            textShadow: '0px 0px 2px #000'
                                        }}>{values.name}</div>
                                    </div>
                                </Col>
                            )
                        })}
                        <Col xs={12} style={{
                            width: '100%',
                            height: 'auto',
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            display: 'flex',
                            flexDirection: 'row'
                        }}><Button variant='danger' style={{
                            borderRadius: 100,
                            height: 80,
                            width: 80,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                            onClick={(e) => {
                                e.preventDefault();
                                history('/newprofile')
                            }}><FontAwesomeIcon icon={faPlus} className='fa-fw fa-2x' /></Button>
                        </Col>
                    </Row>
                </MobileLayout>
            }
        </>
    );
}

export default MainMobile;