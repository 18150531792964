import React, { useEffect, useState } from 'react';
import AdminLayout from '../../Layouts/AdminLayout'
import { Button, Row, Col, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Helpers from '../../Libs/Helpers';
import MovieCard from '../Movies/MovieCard';

function Movies(props) {

    const history = useNavigate()
    const [allMovies, setAllMovies] = useState([])
    const [totalMovies, setTotalMovies] = useState()
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState('')
    const [showMax, setShowMax] = useState(12)

    function handleClick(path) {
        history('/admin/' + path);
    }

    useEffect(() => {
        if (!loading) {
            Helpers.queryAuth('/api/movies/all', 'POST', localStorage.getItem('token'), { id: 1 })
                .then((data) => {
                    if(data.length === 0){
                        setTotalMovies(0)
                        setLoading(true)
                    } else {
                        setAllMovies(data)
                        setTotalMovies(data.length)
                    }
                })
            if (allMovies && totalMovies) {
                setLoading(true)
            }
        }
    }, [allMovies, totalMovies, loading])

    return (
        <AdminLayout>
            {!loading ? <div className='d-flex justify-content-center align-items-center w-100 vh-75 mt-5'><Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} /></div> : <>
                <div className='d-flex w-100 justify-content-between align-items-center mb-4'>
                    <div className='d-flex'>
                        <div className='text-light fw-bold h3'>
                            Gestión de Películas ({totalMovies})
                        </div>
                    </div>
                    <div className='d-flex'>
                        <Form.Control type="text" placeholder='Buscar' onChange={(e) => { setFiltro(e.target.value) }} />
                        <Button variant='danger' onClick={(e) => { e.preventDefault(); handleClick("newmovie") }} style={{ width: 400, marginLeft: 10 }}>
                            <FontAwesomeIcon icon={faPlus} className='me-3' />
                            Agregar Película</Button>
                    </div>
                </div>
                <Row>
                    {allMovies.filter(item => item.name.includes(filtro)).slice(0, showMax).map((values, index) => {
                        return (
                            <Col key={index} sm={2}>
                                <MovieCard name={values.name} id_title={values._id} extension={values.poster_extension} producer={values.producer} views={4} brokers={1} />
                            </Col>
                        )
                    })}
                </Row>
                <div className='d-flex justify-content-center align-items-center'>
                    <Button className='rounded-pill shadow-sm' variant='danger' onClick={(e) => {
                        e.preventDefault();
                        setShowMax(showMax + 12)
                    }}
                        style={{ width: 80, height: 80 }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='fa-2x' />
                    </Button>
                </div>
            </>
            }
        </AdminLayout>
    );
}

export default Movies;