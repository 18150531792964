import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout';

function MainAdmin(props) {
    return (
        <AdminLayout>
        <div className='text-light fw-bold h3'>
            Gestión y Administración
        </div>
        </AdminLayout>
    );
}

export default MainAdmin;