import React, { useEffect, useState } from 'react';
import TVLayout from '../../Layouts/TVLayout'
import { Row, Col, Card, Spinner } from 'react-bootstrap'
import Helpers from '../../Libs/Helpers';
import { useNavigate } from 'react-router-dom'

function HomeTV(props) {

    const [productoras, setProductoras] = useState([])
    const [movies, setMovies] = useState([])
    const [loader, setLoader] = useState(false)

    const history = useNavigate()
    
    useEffect(() => {
        Helpers.queryAuth('/api/producers/', 'POST', localStorage.getItem('auth'), {}).then((data) => {
            setProductoras(data)
            Helpers.queryAuth('/api/movies/allrecomended', 'POST', localStorage.getItem('auth'), { id_user: localStorage.getItem('id') }).then((data) => {
                setMovies(data)
                setLoader(true)
            })
        })
        
    }, [])

    return (
        <TVLayout>
            {!loader ? <div className='d-flex justify-content-center align-items-center w-100 vh-75 mt-5'><Spinner variant='danger' animation="border" style={{ height: 150, width: 150 }} /></div> : <>
            <Row>
                {productoras.map((values, index) => {
                    return (
                        <Col key={index} sm={3}>
                            <Card className='w-100 mb-3'>
                                <Card.Body className='d-flex justify-content-center align-items-center' style={{
                                    padding: 20,
                                    height: 130,
                                    width: '100%'
                                }}>
                                    <div
                                        className='d-flex'
                                        style={{
                                            width: '100%',
                                            height: '130px',
                                            borderRadius: 0,
                                            backgroundSize: 'contain',
                                            backgroundImage: `url(${Helpers.API}/cdn/${values._id}.png)`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center'
                                        }}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            <div className='text-light h3 fw-bold'>Agregados Recientemente</div>
            <Row>
                {movies.map((values, index) => {
                    return (
                        <Col sm={2} key={index} className='mb-3'>
                            <div style={{
                                backgroundImage: `url(${Helpers.API}/cdn/poster_${values._id}.${values.poster_extension})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '280px',
                                borderRadius: 10,
                                border: '1px solid #c9c9c9',
                                boxShadow: '0px 0px 8px #c9c9c9',
                                cursor: 'pointer'
                            }} onClick={(e) => {
                                e.preventDefault();
                                localStorage.setItem('movie', JSON.stringify(values));
                                localStorage.setItem('token', localStorage.getItem('auth'))
                                history('/viewmovie')
                            }} />
                        </Col>

                    )
                })}
            </Row>
            </>
            }
        </TVLayout>
    );
}

export default HomeTV;