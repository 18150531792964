import React, { useState } from 'react';
import AdminLayout from '../../Layouts/AdminLayout';
import { Row, Col, Form, InputGroup, Button, Image } from 'react-bootstrap'
import InputGroupText from 'react-bootstrap/esm/InputGroupText';
import Helpers from '../../Libs/Helpers';
import { useNavigate } from 'react-router-dom'

function NewProducers(props) {

    const history = useNavigate()

    const [icon, setFileData] = useState(null);
    const [name, setName] = useState(null);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setFileData(base64);
    };

    const saveProducer = (e) => {
        e.preventDefault();
        if (name && icon) {
            Helpers.queryAuth('/api/producers/add', 'POST', localStorage.getItem('token'), { name, icon }).then((data) => {
                if(data){
                    history('/admin/producers')
                }
            })
        }
    }

    return (
        <AdminLayout>
            <div className='text-light fw-bold h3 mb-5'>Registro de Productora</div>
            <Row>
                <Col sm={6}>
                    <InputGroup>
                        <InputGroupText>Productora</InputGroupText>
                        <Form.Control type='text'
                            defaultValue={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Image style={{ width: '100%', height: 'auto', borderRadius: 10, marginTop: 20 }} src={icon} />
                    </InputGroup>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control
                            type="file" accept='.jpeg, .jpg, .png'
                            onChange={(e) => handleFileUpload(e)}
                        />
                    </Form.Group>
                    <Button variant='danger' className='mt-4' onClick={(e) => { saveProducer(e) }}>Crear Productora</Button>
                </Col>
            </Row>
        </AdminLayout>
    );
}

export default NewProducers;