import React from 'react';
import { Card } from 'react-bootstrap'

function CardProducer(props) {

    return (
        <Card className='shadow-sm mb-3'>
            <Card.Body className='d-flex justify-content-center align-items-center'>
                <div style={{
                    width: '100%',
                    height: '80px',
                    borderRadius: 0,
                    backgroundSize: 'contain',
                    backgroundImage: `url(${props.icon})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }} />
            </Card.Body>
            <Card.Footer>
                <div className='fw-bold h5'>{props.name}</div>
                <div>Películas: {props.peliculas}</div>
                <div>Series: {props.series}</div>
            </Card.Footer>
        </Card>
    );
}

/* <Image className='d-flex' style={{ width: 160, height: 'auto', border: 0 }} src={props.icon} /> */

export default CardProducer;